import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styles from './navbar.module.scss';
import toggleStyle from '../menu-toggle-button/menu-toggle-button.module.scss';
import Button from '../button';
import Link from '../link';
import Text from '../text';
import { useDeviceDetect, useScroll } from 'hooks';
import SitesSection from '@/components/sites-section';
import { ctaEvents } from '@/lib/gtm';
import appConfig from '../../config.json';

const Navbar = ({
	configuration,
	logo,
	symbol,
	symbolSiteLinks,
	showSymbolSiteLinksWithNavbarMode,
	showSymbolSiteLinksInNavbar,
	announcementMessage,
	links,
	siteLinks,
	defaultVisitType,
}) => {
	const [showMenu, setShowMenu] = useState(false);
	const [announcementMessageDismissed, setAnnouncementMessageDismissed] = useState(false);
	const router = useRouter();
	const { isSmallScreen } = useDeviceDetect();
	const { isScrolled, scrollY } = useScroll();
	const [active, setActive] = useState(false);
	const [showPrimaryAction, setShowPrimaryAction] = useState(false);

	useEffect(() => {
		return () => document.body.classList.remove('noscroll');
	}, []);

	useEffect(() => {
		if (active) {
			setShowPrimaryAction(true);
		} else {
			if (isScrolled && scrollY >= 420) {
				setShowPrimaryAction(true);
			} else {
				setShowPrimaryAction(false);
			}
		}
	}, [active, setShowPrimaryAction, isScrolled, scrollY]);

	const showLogo =
		!isSmallScreen || (isSmallScreen && !(isScrolled && scrollY >= 420) && !active);

	const closeAnnouncementMessage = () => {
		setAnnouncementMessageDismissed(true);
	};

	const toggle = () => {
		if (!showMenu) {
			setTimeout(() => {
				document.getElementById('content').classList.toggle('noscroll');
			}, 250);
			setShowMenu(!showMenu);
		} else {
			document.getElementById('content').classList.toggle('noscroll');
			setTimeout(() => {
				setShowMenu(!showMenu);
			}, 150);
		}
	};

	const getButton = () => {
		return (
			<div
				className={toggleStyle.toggle + (active ? ' ' + toggleStyle.active : '')}
				onClick={() => {
					setActive(!active), toggle();
				}}
			>
				<span></span>
				<span></span>
				<span></span>
			</div>
		);
	};

	if (isSmallScreen) {
		return (
			<>
				<nav className={styles.navbar}>
					{!showMenu && !!announcementMessage && !announcementMessageDismissed && (
						<div className={styles.announcementBox}>
							<div className={styles.announcementMessage}>
								<Text tag={'subHeader'}>{announcementMessage}</Text>
							</div>

							<div className={styles.announcementMessageClose}>
								<Button
									type="image"
									icon="/icons/times.svg"
									text="Stäng"
									onClick={closeAnnouncementMessage}
								/>
							</div>
						</div>
					)}

					<div className={styles.navBarContentContainer}>
						<div
							className={
								styles.logoContainer + (!showLogo ? ' ' + styles.symbol : '')
							}
						>
							<Link
								icon={showLogo ? logo : symbol}
								text="Logo"
								iconHeight={32}
								href="/"
								variant="image"
							/>
						</div>
						{showPrimaryAction && !!configuration.showWizardButtonInNavbar && (
							<div className={styles.vertical}>
								<Button
									text={
										configuration?.siteConfiguration?.metadata.ctaTitleNavbar ??
										'LÄÄKÄRIN ARVIOON'
									}
									type="outlined"
									onClick={() => {
										ctaEvents(
											'hero',
											configuration?.wizardConfiguration?.diagnosisType,
											defaultVisitType,
										);
										sessionStorage.setItem('currentUrl', router.asPath);
										router.push(
											'/arivo?' +
												(defaultVisitType === 'Revisit'
													? 'revisit=true'
													: ''),
										);
									}}
								/>
							</div>
						)}
						{getButton()}
					</div>
					{!!showSymbolSiteLinksInNavbar && (
						<SitesSection
							symbolSiteLinks={symbolSiteLinks}
							navbarMode={!!showSymbolSiteLinksWithNavbarMode}
						/>
					)}
				</nav>
				<div className={styles.menu + (showMenu ? ' ' + styles.open : '')}>
					<div className={styles.leftColumn}>
						<ul>
							{links.map((link) => (
								<li key={link.url}>
									<Link href={link.url} text={link.text} variant="bigOnDesktop" />
								</li>
							))}
							{configuration.site !== 'Urinkollenfi' && (
								<li>
									<Link
										href="/vara-kollar"
										text={
											configuration.site !== 'Urinkollenfi'
												? 'Våra tjänster'
												: 'Palvelumme'
										}
										onClick={() => {
											setActive(false);
											toggle();
										}}
										variant="bigOnDesktop"
									/>
								</li>
							)}
							<li>
								<Link
									href={
										configuration.site !== 'Urinkollenfi'
											? '/artiklar'
											: '/artikkelit'
									}
									text={
										configuration.site !== 'Urinkollenfi'
											? 'Våra artiklar'
											: 'Artikkelit'
									}
									onClick={() => {
										setActive(false);
										toggle();
									}}
									variant="bigOnDesktop"
								/>
							</li>
							<li>
								<Link
									href={
										appConfig.patientPortalURL ?? 'https://patient.kollarna.se'
									}
									text={
										configuration.site !== 'Urinkollenfi'
											? 'Logga in'
											: 'Kirjaudu sisään'
									}
									onClick={() => {
										setActive(false);
										toggle();
									}}
									variant="bigOnDesktop"
								/>
							</li>
						</ul>
						<div className={styles.social}>
							{/* 2021-12-03: Hide social links for now:
              <Link
                href="https://www.instagram.com/urinkollen"
                variant="icon"
                icon="/icons/instagram.svg"
                text="Instagram"
              />
              <Link
                href="https://twitter.com/urinkollen"
                variant="icon"
                icon="/icons/twitter.svg"
                text="Twitter"
              />
              <Link
                href={"mailto:info@" + config.site.toLowerCase() + ".se"}
                variant="icon"
                icon="/icons/envelope.svg"
                text="Kontakta oss"
              /> */}
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<nav className={styles.navbar}>
				{!showMenu && !!announcementMessage && !announcementMessageDismissed && (
					<div className={styles.announcementBox}>
						<div className={styles.announcementMessage}>
							<Text tag={'subHeader'}>{announcementMessage}</Text>
						</div>
						<div className={styles.announcementMessageClose}>
							<Button
								type="image"
								icon="/icons/times.svg"
								text="Stäng"
								onClick={closeAnnouncementMessage}
							/>
						</div>
					</div>
				)}

				<div className={styles.navBarContentContainer}>
					<div className={styles.logoContainer}>
						{showLogo && (
							<Link
								icon={logo}
								text="Logo"
								iconHeight={32}
								href="/"
								variant="image"
							/>
						)}
					</div>
					{showPrimaryAction && !!configuration.showWizardButtonInNavbar && (
						<div className={styles.vertical}>
							<Button
								text={
									configuration?.siteConfiguration?.metadata.ctaTitleNavbar ??
									'LÄÄKÄRIN ARVIOON'
								}
								type="outlined"
								onClick={() => {
									ctaEvents(
										'hero',
										configuration?.wizardConfiguration?.diagnosisType,
										defaultVisitType,
									);
									sessionStorage.setItem('currentUrl', router.asPath);
									router.push(
										'/arivo' +
											(defaultVisitType === 'Revisit' ? 'revisit=true' : ''),
									);
								}}
							/>
						</div>
					)}
					{getButton()}
				</div>
				{!!showSymbolSiteLinksInNavbar && (
					<SitesSection
						symbolSiteLinks={symbolSiteLinks}
						navbarMode={!!showSymbolSiteLinksWithNavbarMode}
					/>
				)}
			</nav>

			<div className={styles.menu + (showMenu ? ' ' + styles.open : '')}>
				<div className="container full-height">
					<div className="row">
						<div className="col-8">
							<ul>
								{links.map((link) => (
									<li key={link.url}>
										<Link
											href={link.url}
											text={link.text}
											onClick={() => {
												setActive(false);
												toggle();
											}}
											variant="bigOnDesktop"
										/>
									</li>
								))}
								{configuration.site !== 'Urinkollenfi' && (
									<li>
										<Link
											href="/vara-kollar"
											text={
												configuration.site !== 'Urinkollenfi'
													? 'Våra tjänster'
													: 'Palvelumme'
											}
											onClick={() => {
												setActive(false);
												toggle();
											}}
											variant="bigOnDesktop"
										/>
									</li>
								)}
								<li>
									<Link
										href={
											configuration.site !== 'Urinkollenfi'
												? '/artiklar'
												: '/artikkelit'
										}
										text={
											configuration.site !== 'Urinkollenfi'
												? 'Våra artiklar'
												: 'Artikkelit'
										}
										onClick={() => {
											setActive(false);
											toggle();
										}}
										variant="bigOnDesktop"
									/>
								</li>
								<li>
									<Link
										href={
											appConfig.patientPortalURL ??
											'https://patient.kollarna.se'
										}
										text={
											configuration.site !== 'Urinkollenfi'
												? 'Logga in'
												: 'Kirjaudu sisään'
										}
										onClick={() => {
											setActive(false);
											toggle();
										}}
										variant="bigOnDesktop"
									/>
								</li>
							</ul>
							<div className={styles.social}>
								{/* 2021-12-03: Hide social links for now:
                <Link
                  href="https://www.instagram.com/urinkollen"
                  variant="icon"
                  icon="/icons/instagram.svg"
                  text="Instagram"
                />
                <Link
                  href="https://twitter.com/urinkollen"
                  variant="icon"
                  icon="/icons/twitter.svg"
                  text="Twitter"
                />
                <Link
                  href={"mailto:info@" + config.site.toLowerCase() + ".se"}
                  variant="icon"
                  icon="/icons/envelope.svg"
                  text="Kontakta oss"
                /> */}
							</div>
						</div>
						{configuration.site !== 'Urinkollenfi' && (
							<div className={styles.rightColumn + ' col-4'}>
								<Text>
									{!!configuration.menuSiteLinksDescription &&
										configuration.menuSiteLinksDescription}
								</Text>
								{siteLinks.map((site) => (
									<div key={site.alt}>
										<Link
											href={site.url}
											variant="image"
											icon={site.image.url}
											alt={site.alt}
											iconHeight={32}
										/>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

Navbar.propTypes = {
	logo: PropTypes.string.isRequired,
	symbol: PropTypes.string.isRequired,
	links: PropTypes.array.isRequired,
	siteLinks: PropTypes.array.isRequired,
	defaultVisitType: PropTypes.string.isRequired,
};

Navbar.defaultProps = {};

export default Navbar;
